const COMPANY_ID_KEY = 'companyId';

export const setCompanyId = (companyIdUrlParam: string | string[]): void => {
  const companyId = typeof companyIdUrlParam === 'string'
    ? companyIdUrlParam
    : companyIdUrlParam[0];

  sessionStorage.setItem(COMPANY_ID_KEY, companyId);
};

export const getCompanyId = (): string | undefined => {
  if (sessionStorage) {
    return sessionStorage.getItem(COMPANY_ID_KEY) ?? undefined;
  }
};
