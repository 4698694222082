import * as React from 'react';
import { convertRequestHook } from 'web-ui/src/api/convertRequestHook';
import { styled } from '../../Layout/ThemeProvider/ThemeProvider';
import { CsvConversionFile, SupportedBanks, UploadFileToConvert } from '../UploadFileToConvert/UploadFileToConvert';

const Container = styled.div`
  display: flex;
  width: 90rem;
  min-height: 20rem;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

type Props = {
  bankId?: SupportedBanks;
  getCompanyId?: () => string;
  onUploadSuccess(onUploadParams: OnUploadSuccessParams);
};

const onFailure = (...args) => {
  console.error('Upload failure', args);
};

type OnUploadSuccessParams = {
  jobId: string;
  fileName: string;
};

export const PdfToCsvConverter: React.FC<Props> = props => {
  const [droppedFile, setDroppedFile] = React.useState<CsvConversionFile | undefined>(undefined);

  const onUploadSuccess = ({ jobId, fileName }: OnUploadSuccessParams) => {
    props.onUploadSuccess({ jobId, fileName });
  };

  const { isSending, handleSend } = convertRequestHook({ onUploadSuccess, onUploadFailure: onFailure });

  const onDropHandler = async (file: File, bankName?: SupportedBanks) => {
    setDroppedFile({ data: file, ...(bankName ? { bankName } : {}) });

    return handleSend(file, bankName, props.getCompanyId?.());
  };

  return (
    <Container>
      <FilesContainer>
        <UploadFileToConvert
          bankId={props.bankId}
          droppedFile={droppedFile}
          onDrop={onDropHandler}
        />
      </FilesContainer>
    </Container>
  );
};
