import * as React from 'react';
import Dropzone from 'react-dropzone';
import { styled } from '../../Layout/ThemeProvider/ThemeProvider';

const DEFAULT_MESSAGE = 'Przeciągnij i upuść plik';

export type SupportedBanks = 'mBiznes'
  | 'pkoBp'
  | 'mBankCorpHtml'
  | 'mBankCsv'
  | 'mBank'
  | 'mBankLo'
  | 'mBiznesEzo'
  | 'ideabank'
  | 'spoldzielczy'
  | 'alior-optima'
  | 'alior-quick-books'
  | 'iPko'
  | string;

export type CsvConversionFile = {
  data: File;
  bankId?: SupportedBanks;
  isConverting?: boolean;
  isDownloading?: boolean;
  downloadLink?: string;
};

export const mapFilesToCsvConversionFiles =
  (files: File[], bankName: SupportedBanks): CsvConversionFile[] => files.map(file => ({
    bankId: bankName,
    data: file,
  }));

const StyledDropzone = styled(Dropzone)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  user-select: none;

  &.dropzone {
    border: 5px dashed ${({ theme }) => theme.colors.primary4};
    border-radius: 10rem;
    min-height: 14rem;
    width: 50rem;
    display: flex;

    text-align: center;

    &:hover {
      border: 5px dashed ${({ theme }) => theme.colors.primary5};
    }
  }

  input[type=file] {
    text-align: center;
    width: 100%;
  }
`;

export const FileListContainer = styled.div<{ fileDropped?: boolean }>`
  width: 100%;
  max-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 0 1rem;
  cursor: ${({ fileDropped }) => fileDropped ? 'default' : 'pointer'};
`;

const TextParagraph = styled.p`
  text-align: center;
  font-size: 2rem;
  line-height: 1.8rem;
  margin: 1rem;
`;

type Props = {
  bankId?: SupportedBanks;
  droppedFile?: CsvConversionFile;
  onDrop: (file: File, bankName?: SupportedBanks) => Promise<void>;
};

export const UploadFileToConvert: React.FC<Props> = props => {
  const { bankId, droppedFile, onDrop } = props;

  return (
    <StyledDropzone
      accept=".pdf,.html,.csv"
      className="dropzone"
      onDrop={async accepted => {
        const droppedFiles: File[] = accepted;
        if (bankId) {
          await onDrop(droppedFiles[0], bankId);
        } else {
          await onDrop(droppedFiles[0]);
        }
      }}
    >
      <FileListContainer
        fileDropped={!!droppedFile}
      >
        <TextParagraph>
          {DEFAULT_MESSAGE}
        </TextParagraph>
      </FileListContainer>
    </StyledDropzone>
  );
};
